import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import closeWhite from 'images/icons/layout/Close-White.svg';
import hamburgerWhite from 'images/icons/layout/Hamburger.svg';
import searchWhite from 'images/icons/layout/Search.svg';
import Alerts from '@/components/shared/alerts/Alerts';

import { globalImage } from '@/types';
import Footer from './Footer';
import MobileMenuItem from './shared/MobileMenuItem';

const MobileNavigationMenu = ({
  alerts,
  copyright,
  corporateAddress,
  footerMenuItems,
  globalImages,
  hasAlerts,
  hasStickyNav,
  legalMenuItems,
  mainMenuItems,
  searchUrl,
  isWhiteLabel
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    if (isMenuVisible) {
      document.body.classList.remove('nav-open');
    } else {
      document.body.classList.add('nav-open');
    }

    setIsMenuVisible(!isMenuVisible);
  };

  const getMenuToggleIcon = () => {
    if (isMenuVisible) {
      return closeWhite;
    }

    return hamburgerWhite;
  };

  return (
    <nav
      className={`nav${hasStickyNav ? '' : ' relative'}${isMenuVisible ? ' expanded' : ''}${
        isWhiteLabel ? ' white-label' : ''
      }`}
      id="nav-mobile"
      aria-label="Mobile Navigation Menu"
    >
      {hasAlerts && <Alerts alerts={alerts} />}
      <div className="container-fluid">
        <div className="nav-wrap">
          <div className="logo logo-crossfade">
            <a href="/" title="Go to Home">
              <img
                id="top-logo-mobile"
                alt="HealthBenefits Logo"
                src={globalImages.logoInverse && globalImages.logoInverse.directUrl}
              />
            </a>
          </div>
          {!isWhiteLabel && (
            <ul className="nav-cta">
              <li className="search">
                <a href={searchUrl} title="Go to search" className="search">
                  <img src={searchWhite} alt="Search Icon" />
                </a>
              </li>
              <li className="menu-toggle">
                <button onClick={toggleMenu} className="btn-no-style" type="button">
                  <img src={getMenuToggleIcon()} alt="Mobile Menu" />
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      {!isWhiteLabel && (
        <AnimateHeight duration={500} height={isMenuVisible ? 'auto' : 0} animateOpacity>
          <div className="primary-navigation-wrapper">
            <ul className="primary-navigation">
              {mainMenuItems &&
                mainMenuItems.map((item) => (
                  <MobileMenuItem key={item.name} {...item} businessUnits={item.businessUnits} />
                ))}
            </ul>
            <section className="footer-links">
              <Footer
                ccpaBanner={null}
                copyright={copyright}
                corporateAddress={corporateAddress}
                footerMenuItems={footerMenuItems}
                globalImages={globalImages}
                isMobileNavigation
                legalMenuItems={legalMenuItems}
              />
            </section>
          </div>
        </AnimateHeight>
      )}
    </nav>
  );
};

MobileNavigationMenu.defaultProps = {
  alerts: [],
  searchUrl: ''
};

MobileNavigationMenu.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string.isRequired,
  corporateAddress: PropTypes.string.isRequired,
  footerMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalImages: globalImage.isRequired,
  hasAlerts: PropTypes.bool.isRequired,
  hasStickyNav: PropTypes.bool.isRequired,
  legalMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchUrl: PropTypes.string,
  isWhiteLabel: PropTypes.bool.isRequired
};

export default MobileNavigationMenu;
