import React from 'react';
import PropTypes from 'prop-types';

import searchWhite from 'images/icons/layout/Search.svg';
import Alerts from '@/components/shared/alerts/Alerts';
import { globalImage } from '@/types';
import DesktopMenuItem from './shared/DesktopMenuItem';

const DesktopNavigationMenu = ({
  alerts,
  currentUrl,
  mainMenuItems,
  globalImages,
  hasAlerts,
  hasStickyNav,
  searchUrl,
  isWhiteLabel
}) => (
  <nav
    className={`nav${hasStickyNav ? '' : ' relative'}${isWhiteLabel ? ' white-label' : ''}`}
    id="nav-desktop"
    aria-label="Desktop Navigation Menu"
  >
    {hasAlerts && <Alerts alerts={alerts} />}
    <div className="inner">
      <div className="container-fluid">
        <div className="nav-wrap">
          <div className="logo logo-crossfade">
            <a href="/" title="Go to Home">
              <img
                id="bottom-logo-desktop"
                alt="HealthBenefits Logo"
                src={globalImages.logoInverse && globalImages.logoInverse.directUrl}
              />
            </a>
          </div>
          {!isWhiteLabel && (
            <ul className="primary-navigation">
              {mainMenuItems &&
                mainMenuItems.map((item) => (
                  <DesktopMenuItem
                    currentUrl={currentUrl}
                    key={item.name}
                    {...item}
                    businessUnits={item.businessUnits}
                  />
                ))}
              <li className="search">
                <a href={searchUrl} title="Go to search">
                  <img src={searchWhite} alt="Search Icon" />
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  </nav>
);

DesktopNavigationMenu.defaultProps = {
  alerts: [],
  searchUrl: ''
};

DesktopNavigationMenu.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  currentUrl: PropTypes.string.isRequired,
  mainMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalImages: globalImage.isRequired,
  hasAlerts: PropTypes.bool.isRequired,
  hasStickyNav: PropTypes.bool.isRequired,
  searchUrl: PropTypes.string,
  isWhiteLabel: PropTypes.bool.isRequired
};

export default DesktopNavigationMenu;
