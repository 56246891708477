import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sizedImageShape } from '@/types';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';

const SimpleProduct = ({ image, url, urlTarget, altText, title, shortDescription }) => (
  <div className={classNames('tile', { 'contains-link': url })}>
    <div className="tile-container">
      <ConditionalWrapper
        condition={url}
        wrapper={(children) => (
          <a className="btn-no-style" href={url} target={urlTarget}>
            {children}
          </a>
        )}
      >
        <LazyLoadImg src={image} alt={altText || `${title} Tile`} />
        <div className="content">
          {title && <h3>{title}</h3>}
          {shortDescription && <p dangerouslySetInnerHTML={{ __html: shortDescription }} />}
        </div>
      </ConditionalWrapper>
    </div>
  </div>
);

SimpleProduct.defaultProps = {
  altText: ''
};

SimpleProduct.propTypes = {
  image: sizedImageShape.isRequired,
  url: PropTypes.string.isRequired,
  urlTarget: PropTypes.string.isRequired,
  altText: PropTypes.string,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired
};

export default SimpleProduct;
