import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import Helmet from 'react-helmet';

import getStore from './state/store';
import initializeSite from './services/initialization.service';

import NavigationMenu from './components/layout/NavigationMenu';

import LargeHeroWidget from './components/widgets/hero/LargeHeroWidget';
import TestimonialComponent from './components/widgets/testimonials/TestimonialComponent';
import IntroComponent from './components/widgets/intro/IntroComponent';
import AnimationComponent from './components/widgets/animation/AnimationComponent';
import SmallHeroWidget from './components/widgets/hero/SmallHeroWidget';
import HighlightsComponent from './components/widgets/highlights/HighlightsComponent';
import HtmlSnippetComponent from './components/widgets/htmlSnippet/HtmlSnippetComponent';
import FeaturedContentImageComponent from './components/widgets/featuredContent/ImageComponent';
import FeaturedContentNewsroomComponent from './components/widgets/featuredContent/NewsroomComponent';
import BusinessUnitSocialFooterComponent from './components/widgets/businessUnits/SocialFooterComponent';
import DetailedContentComponent from './components/widgets/detailedContent/DetailedContentComponent';
import FeaturedContentVideoComponent from './components/widgets/featuredContent/VideoComponent';
import ProductsServicesComponent from './components/widgets/productsServices/ProductsServicesComponent';
import LegalDisclaimerComponent from './components/widgets/legalDisclaimer/LegalDisclaimerComponent';
import DataTableComponent from './components/shared/dataTable/DataTableComponent';
import TextComponent from './components/widgets/text/TextComponent';
import ProductNetworkAvailabilityComponent from './components/widgets/productNetworkAvailability/ProductNetworkAvailabilityComponent';
import SearchBarComponent from './components/widgets/searchBar/SearchBarComponent';
import WhiteLabelSearchBarComponent from './components/widgets/whiteLabelSearchBar/WhiteLabelSearchBarComponent';
import QuickCtaComponent from './components/widgets/quickCta/QuickCtaComponent';
import FaqComponent from './components/widgets/faq/FaqComponent';
import MultipleItemComponent from './components/widgets/multipleItem/MultipleItemComponent';
import GeneralTextComponent from './components/widgets/generalText/GeneralTextComponent';
import PrivacyPolicyComponent from './components/widgets/privacyPolicy/PrivacyPolicyComponent';
import ProductsByBUComponent from './components/widgets/productsByBU/ProductsByBUComponent';
import ProductNavigationComponent from './components/widgets/productNavigation/ProductNavigationComponent';
import ConversionLinkComponent from './components/widgets/conversionLink/ConversionLinkComponent';
import FullWidthImageCarouselComponent from './components/widgets/fullWidthImageCarousel/FullWidthImageCarouselComponent';
import SimpleProductsServicesComponent from './components/widgets/simpleProductsServices/SimpleProductsServicesComponent';
import WhiteLabelSmallHeroBannerWidget from './components/widgets/hero/WhiteLabelSmallHeroBannerWidget';
import IconText30By70ContentComponent from './components/widgets/IconText30By70Content/IconText30By70ContentComponent';
import WhiteLabelNavigationContentComponent from './components/widgets/whiteLabelNavigationContent/WhiteLabelNavigationContentComponent';
// Landing Pages
import NewsroomDetailContent from './components/newsroom/newsroomDetail/NewsroomDetailContent';
import NewsroomLanding from './components/newsroom/newsroomLanding/NewsroomLanding';
import ClaimsLanding from './components/claims/ClaimsLanding';
import PaperClaimsLanding from './components/claims/PaperClaimsLanding';
import SearchLanding from './components/searchLanding/SearchLanding';
import ClientSearchLanding from './components/clientSearchLanding/ClientSearchLanding';
import SitemapLanding from './components/sitemap/SitemapLanding';

// Layout Components
import Footer from './components/layout/Footer';

// Shared Components
import BreadCrumb from './components/shared/breadCrumb/BreadCrumb';
import Scroller from './components/shared/scroller/Scroller';
import CCPABanner from './components/shared/ccpaBanner/CCPABanner';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const store = getStore();

initializeSite(store.dispatch);

// All JavaScript in here will be loaded server-side
// Expose components globally so ReactJS.NET can use them
global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;
global.Helmet = Helmet;

// Global components
global.NavigationMenu = NavigationMenu;

// Widget components
global.LargeHeroWidget = LargeHeroWidget;
global.SmallHeroWidget = SmallHeroWidget;
global.ProductsServicesComponent = ProductsServicesComponent;
global.TestimonialComponent = TestimonialComponent;
global.IntroComponent = IntroComponent;
global.AnimationComponent = AnimationComponent;
global.HighlightsComponent = HighlightsComponent;
global.HtmlSnippetComponent = HtmlSnippetComponent;
global.FeaturedContentImageComponent = FeaturedContentImageComponent;
global.FeaturedContentVideoComponent = FeaturedContentVideoComponent;
global.FeaturedContentNewsroomComponent = FeaturedContentNewsroomComponent;
global.BusinessUnitSocialFooterComponent = BusinessUnitSocialFooterComponent;
global.DetailedContentComponent = DetailedContentComponent;
global.ProductsServicesComponent = ProductsServicesComponent;
global.LegalDisclaimerComponent = LegalDisclaimerComponent;
global.DataTableComponent = DataTableComponent;
global.TextComponent = TextComponent;
global.ProductNetworkAvailabilityComponent = ProductNetworkAvailabilityComponent;
global.SearchBarComponent = SearchBarComponent;
global.WhiteLabelSearchBarComponent = WhiteLabelSearchBarComponent;
global.QuickCtaComponent = QuickCtaComponent;
global.FaqComponent = FaqComponent;
global.MultipleItemComponent = MultipleItemComponent;
global.GeneralTextComponent = GeneralTextComponent;
global.PrivacyPolicyComponent = PrivacyPolicyComponent;
global.ProductsByBUComponent = ProductsByBUComponent;
global.ProductNavigationComponent = ProductNavigationComponent;
global.ConversionLinkComponent = ConversionLinkComponent;
global.FullWidthImageCarouselComponent = FullWidthImageCarouselComponent;
global.SimpleProductsServicesComponent = SimpleProductsServicesComponent;
global.WhiteLabelSmallHeroBannerWidget = WhiteLabelSmallHeroBannerWidget;
global.IconText30By70ContentComponent = IconText30By70ContentComponent;
global.WhiteLabelNavigationContentComponent = WhiteLabelNavigationContentComponent;
// Content components
global.NewsroomDetailContent = NewsroomDetailContent;
global.NewsroomLanding = NewsroomLanding;
global.ClaimsLanding = ClaimsLanding;
global.PaperClaimsLanding = PaperClaimsLanding;
global.SearchLanding = SearchLanding;
global.ClientSearchLanding = ClientSearchLanding;
global.SitemapLanding = SitemapLanding;

// Layout Components
global.Footer = Footer;

// Shared Components
global.BreadCrumb = BreadCrumb;
global.Scroller = Scroller;
global.CCPABanner = CCPABanner;
