import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import { getSectionClassNames } from '@/utilities/component-color';

import Item from './Item';

const MultipleItemComponent = ({
  hasContentDivider,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  thirdsGrid,
  title,
  items,
  componentBackgroundColor
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="multiple-item-component section-gutter">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          <GlobalComponentContainer
            {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
          />
          <div className={`item-container${thirdsGrid ? ' thirds' : ''}`}>
            {items.map((item) => (
              <Item key={item.title} {...item} />
            ))}
          </div>
        </ConditionalWrapper>
      </div>
      {hasContentDivider && <ContentDivider />}
    </div>
  </section>
);

MultipleItemComponent.defaultProps = {
  hasContentDivider: false,
  parentPageTitle: null,
  parentPageUrl: null,
  prettyUrlTitle: '',
  shortDescription: null,
  thirdsGrid: false,
  title: null,
  items: [],
  componentBackgroundColor: 'white'
};

MultipleItemComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  thirdsGrid: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  componentBackgroundColor: PropTypes.string
};

export default MultipleItemComponent;
