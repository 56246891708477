import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import classNames from 'classnames';
import FeaturedCard from '@/components/shared/card/FeaturedCard';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import { getSectionClassNames } from '@/utilities/component-color';

const FeaturedContentNewsroomComponent = ({
  associatedNewsroomURL,
  featuredItems,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  componentBackgroundColor
}) => (
  <section className={getSectionClassNames(componentBackgroundColor)}>
    <div className="container-fluid">
      <div className="newsroom-featured section-gutter">
        <ConditionalWrapper
          condition={prettyUrlTitle}
          wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
        >
          <GlobalComponentContainer
            {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
          />
          <div className={classNames('newsroom-list', { padded: shortDescription })}>
            {featuredItems.map((item) => (
              <FeaturedCard
                altText={item.altText}
                contentType={item.contentType}
                key={item.link}
                link={item.link}
                title={item.title}
                thumbnailImage={item.thumbnailImage}
                url={associatedNewsroomURL}
              />
            ))}
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  </section>
);

FeaturedContentNewsroomComponent.defaultProps = {
  featuredItems: [],
  parentPageTitle: '',
  parentPageUrl: '',
  prettyUrlTitle: '',
  shortDescription: '',
  componentBackgroundColor: 'white'
};

FeaturedContentNewsroomComponent.propTypes = {
  associatedNewsroomURL: PropTypes.string.isRequired,
  featuredItems: PropTypes.arrayOf(PropTypes.object),
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string.isRequired,
  componentBackgroundColor: PropTypes.string
};

export default FeaturedContentNewsroomComponent;
