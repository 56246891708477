import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import VideoModal from '@/components/widgets/shared/VideoModal';
import PaperClaimsLanding from './PaperClaimsLanding';

const ClaimsLanding = ({
  existingClaimImage,
  existingClaimText,
  fileProcess,
  initialProduct,
  howToVideoUrl,
  howToVideoUrlText,
  instructionOneBackgroundColor,
  instructionOneIcon,
  instructionOneText,
  instructionOneTextColor,
  instructionTwoBackgroundColor,
  instructionTwoIcon,
  instructionTwoText,
  instructionTwoTextColor,
  instructionThreeBackgroundColor,
  instructionThreeIcon,
  instructionThreeText,
  instructionThreeTextColor,
  newClaimImage,
  newClaimText,
  onlineClaimExplanationText,
  onlineClaimShortDescription,
  onlineClaimTitle,
  onlineClaimUrl,
  onlineClaimUrlText,
  products,
  savedClaimImage,
  savedClaimText,
  selectorDescription,
  selectorPlaceholder
}) => {
  const [modalState, setModalState] = useState(false);

  const openModal = () => {
    setModalState(true);
  };

  return (
    <div className="claims section-white">
      <div className="container-fluid">
        <div className="section-gutter">
          <GlobalComponentContainer
            title={onlineClaimTitle}
            shortDescription={onlineClaimShortDescription}
          />
          <div className="online-section">
            <div className="description-section">
              <div className="info">
                <div dangerouslySetInnerHTML={{ __html: onlineClaimExplanationText }} />
                <button
                  className="btn-no-style"
                  title="How to video"
                  type="button"
                  onClick={() => openModal()}
                >
                  <span className="how-to">
                    {howToVideoUrlText || 'View the video on how to file an online claim'}
                  </span>
                </button>
                <a
                  href={onlineClaimUrl}
                  title={onlineClaimUrlText || 'Go to online claims'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                >
                  {onlineClaimUrlText || 'Go to online claims'}
                </a>

                <VideoModal
                  videoUrl={howToVideoUrl}
                  selectFunction={setModalState}
                  isActive={!!modalState}
                />
              </div>
            </div>
            <div className="images-section">
              <div>
                <img src={newClaimImage} alt="New Claim" />
                <p>{newClaimText}</p>
              </div>
              <div>
                <img src={existingClaimImage} alt="Existing Claim" />
                <p>{existingClaimText}</p>
              </div>
              <div>
                <img src={savedClaimImage} alt="Saved Claim" />
                <p>{savedClaimText}</p>
              </div>
            </div>
          </div>
          <ContentDivider />
          <PaperClaimsLanding
            {...{
              fileProcess,
              initialProduct,
              instructionOneBackgroundColor,
              instructionOneIcon,
              instructionOneText,
              instructionOneTextColor,
              instructionTwoBackgroundColor,
              instructionTwoIcon,
              instructionTwoText,
              instructionTwoTextColor,
              instructionThreeBackgroundColor,
              instructionThreeIcon,
              instructionThreeText,
              instructionThreeTextColor,
              products,
              selectorDescription,
              selectorPlaceholder
            }}
          />
        </div>
      </div>
    </div>
  );
};

ClaimsLanding.defaultProps = {
  existingClaimImage: '',
  existingClaimText: '',
  fileProcess: '',
  howToVideoUrl: '',
  howToVideoUrlText: '',
  initialProduct: '',
  instructionOneBackgroundColor: '',
  instructionOneIcon: '',
  instructionOneTextColor: '',
  instructionTwoBackgroundColor: '',
  instructionTwoIcon: '',
  instructionTwoTextColor: '',
  instructionThreeBackgroundColor: '',
  instructionThreeIcon: '',
  instructionThreeTextColor: '',
  newClaimImage: '',
  newClaimText: '',
  onlineClaimExplanationText: '',
  onlineClaimShortDescription: '',
  onlineClaimTitle: '',
  onlineClaimUrl: '',
  onlineClaimUrlText: '',
  savedClaimImage: '',
  savedClaimText: '',
  selectorDescription: '',
  selectorPlaceholder: ''
};

ClaimsLanding.propTypes = {
  existingClaimImage: PropTypes.string,
  existingClaimText: PropTypes.string,
  fileProcess: PropTypes.string,
  howToVideoUrl: PropTypes.string,
  howToVideoUrlText: PropTypes.string,
  initialProduct: PropTypes.string,
  instructionOneBackgroundColor: PropTypes.string,
  instructionOneIcon: PropTypes.string,
  instructionOneText: PropTypes.string.isRequired,
  instructionOneTextColor: PropTypes.string,
  instructionTwoBackgroundColor: PropTypes.string,
  instructionTwoIcon: PropTypes.string,
  instructionTwoText: PropTypes.string.isRequired,
  instructionTwoTextColor: PropTypes.string,
  instructionThreeBackgroundColor: PropTypes.string,
  instructionThreeIcon: PropTypes.string,
  instructionThreeText: PropTypes.string.isRequired,
  instructionThreeTextColor: PropTypes.string,
  newClaimImage: PropTypes.string,
  newClaimText: PropTypes.string,
  onlineClaimExplanationText: PropTypes.string,
  onlineClaimUrl: PropTypes.string,
  onlineClaimUrlText: PropTypes.string,
  onlineClaimShortDescription: PropTypes.string,
  onlineClaimTitle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  savedClaimImage: PropTypes.string,
  savedClaimText: PropTypes.string,
  selectorDescription: PropTypes.string,
  selectorPlaceholder: PropTypes.string
};

export default ClaimsLanding;
