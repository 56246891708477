import React from 'react';
import PropTypes from 'prop-types';
import DataSection from './DataSection';

const DataRow = ({ description, sections, title }) => (
  <div className="data-table-row">
    <section className="data-row">
      <span className="section-header">{title}</span>
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
    </section>
    {sections.length > 0 && (
      <section className="data-section">
        {sections.map((section) => (
          <DataSection
            address={section.mailingAddress}
            contactFormURL={section.contactFormURL}
            contactFormURLText={section.contactFormURLText}
            description={section.groupDescription}
            downloadLink={section.downloadLink}
            downloadLinkText={section.downloadLinkText}
            email={section.email}
            fax={section.fax}
            key={section.guid}
            phone={section.phone}
            title={section.groupTitle}
            webURL={section.webURL}
            webURLText={section.webURLText}
            webURLTarget={section.webURLTarget}
            zip={section.zipCode}
          />
        ))}
      </section>
    )}
  </div>
);

DataRow.defaultProps = {
  description: '',
  sections: [],
  title: ''
};

DataRow.propTypes = {
  description: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
};

export default DataRow;
