import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PredictiveSearch from '@/components/shared/predictiveSearch/PredictiveSearch';
import DataTableComponent from '@/components/shared/dataTable/DataTableComponent';
import FileStep from './FileStep';

const PaperClaimsLanding = ({
  fileProcess,
  initialProduct,
  instructionOneBackgroundColor,
  instructionOneIcon,
  instructionOneText,
  instructionOneTextColor,
  instructionTwoBackgroundColor,
  instructionTwoIcon,
  instructionTwoText,
  instructionTwoTextColor,
  instructionThreeBackgroundColor,
  instructionThreeIcon,
  instructionThreeText,
  instructionThreeTextColor,
  products,
  selectorDescription,
  selectorPlaceholder
}) => {
  const [filteredProductsList, setFilteredProductsList] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [regex, setRegex] = useState(new RegExp());
  const [selectedInputValue, setSelectedInputValue] = useState('');
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isFiltered, setIsFiltered] = useState(false);

  const productsList = products.map((product) => ({
    name: product.claimsProductName,
    rows: product.rows
  }));

  const clearSelection = () => {
    setSelectedInputValue('');
    setSelectedProduct({});
  };

  const getProducts = (businessUnitTitle, businessUnitAlias) => {
    clearSelection();
    setFilteredProductsList(productsList);
    setIsFiltered(false);

    if (businessUnitAlias && businessUnitAlias.length) {
      setFilteredProductsList(
        productsList.filter((product) => product.alias === businessUnitAlias)
      );
      setIsFiltered(true);
    }
  };

  const setUrl = (product) => {
    let currentUrl = window.location.href;

    if (currentUrl.includes('?')) {
      currentUrl = currentUrl.substring(0, currentUrl.indexOf('?'));
    }

    if (product) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, 'Filter Update', `${currentUrl}?product=${product}`);
    }
  };

  const onStateChange = (changes) => {
    const { selectedItem = selectedProduct, inputValue = selectedInputValue } = changes;
    setSelectedProduct(selectedItem);
    setSelectedInputValue(inputValue);

    if (selectedItem) {
      setUrl(selectedItem.name);
    }
  };

  const onChange = (selectedItem) => {
    if (selectedItem) {
      setSelectedProduct(selectedItem);
    } else {
      setSelectedProduct({});
    }
  };

  const setInitialProduct = () => {
    if (initialProduct) {
      const product = products.find(
        (prod) => prod.claimsProductName.toLowerCase() === initialProduct.toLowerCase()
      );
      setSelectedProduct(product);
      setSelectedInputValue(product.claimsProductName);
    }
  };

  const onInputValueChange = (inputValue) => {
    setRegex(new RegExp(`${inputValue}`, 'i'));
  };

  if (!pageLoaded) {
    setPageLoaded(true);
    getProducts();
    setInitialProduct();
  }
  return (
    <div className="section-white">
      <div className="container-fluid">
        <div className="section-gutter">
          <h2 className="center file-process-title">{fileProcess}</h2>
          <PredictiveSearch
            isFiltered={isFiltered}
            items={filteredProductsList}
            onChange={onChange}
            onInputValueChange={onInputValueChange}
            onStateChange={onStateChange}
            placeholder={selectorPlaceholder}
            regex={regex}
            selectedInputValue={selectedInputValue}
            selectedItem={selectedProduct}
          />
          {selectedProduct && selectedProduct.rows && selectedProduct.rows.length ? (
            <>
              <div className="highlights-component predictive">
                <div className="highlights-container">
                  <FileStep
                    backgroundColor={instructionOneBackgroundColor}
                    icon={instructionOneIcon}
                    number="1"
                    text={instructionOneText}
                    textColor={instructionOneTextColor}
                  />
                  <FileStep
                    backgroundColor={instructionTwoBackgroundColor}
                    icon={instructionTwoIcon}
                    number="2"
                    text={instructionTwoText}
                    textColor={instructionTwoTextColor}
                  />
                  <FileStep
                    backgroundColor={instructionThreeBackgroundColor}
                    icon={instructionThreeIcon}
                    number="3"
                    text={instructionThreeText}
                    textColor={instructionThreeTextColor}
                  />
                </div>
              </div>
              <DataTableComponent rows={selectedProduct.rows} />
            </>
          ) : (
            <h2 className="predictive-search-text">{selectorDescription}</h2>
          )}
        </div>
      </div>
    </div>
  );
};

PaperClaimsLanding.defaultProps = {
  fileProcess: '',
  initialProduct: '',
  instructionOneBackgroundColor: '',
  instructionOneIcon: '',
  instructionOneTextColor: '',
  instructionTwoBackgroundColor: '',
  instructionTwoIcon: '',
  instructionTwoTextColor: '',
  instructionThreeBackgroundColor: '',
  instructionThreeIcon: '',
  instructionThreeTextColor: '',
  selectorDescription: '',
  selectorPlaceholder: ''
};

PaperClaimsLanding.propTypes = {
  fileProcess: PropTypes.string,
  initialProduct: PropTypes.string,
  instructionOneBackgroundColor: PropTypes.string,
  instructionOneIcon: PropTypes.string,
  instructionOneText: PropTypes.string.isRequired,
  instructionOneTextColor: PropTypes.string,
  instructionTwoBackgroundColor: PropTypes.string,
  instructionTwoIcon: PropTypes.string,
  instructionTwoText: PropTypes.string.isRequired,
  instructionTwoTextColor: PropTypes.string,
  instructionThreeBackgroundColor: PropTypes.string,
  instructionThreeIcon: PropTypes.string,
  instructionThreeText: PropTypes.string.isRequired,
  instructionThreeTextColor: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectorDescription: PropTypes.string,
  selectorPlaceholder: PropTypes.string
};

export default PaperClaimsLanding;
