import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import SearchIcon from 'images/icons/layout/Search.svg';
import CloseIcon from 'images/icons/layout/Close-Blue.svg';

import { getSearchSuggestions } from '@/services/search.service';
import goToRedirect from '@/services/redirect.service';

const WhiteLabelSearchBarComponent = ({
  colorScheme,
  isFloatingBetweenComponents,
  isFocused,
  onSearch,
  query,
  prettyUrlTitle,
  redirectToSearch,
  searchLinks,
  searchUrl,
  title,
  microSitePath
}) => {
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(query);

  const [isOpen, setIsOpen] = useState(false);

  const hasSearchSuggestions = searchSuggestions && searchSuggestions.length;
  const dropdownOpen = hasSearchSuggestions || isOpen;

  const closeDropdown = () => {
    setIsOpen(false);
    setSearchSuggestions([]);
  };

  const closeDropdownClearValue = () => {
    setIsOpen(false);
    setSearchSuggestions([]);
    setInputValue('');
  };

  return (
    <section
      className={`${
        isFloatingBetweenComponents
          ? ''
          : colorScheme === 'light'
            ? 'section-white search-section'
            : 'section-main search-section search-section-main'
      }`}
    >
      <div className="container-fluid">
        <div className={`${isFloatingBetweenComponents ? 'offset search' : 'search'}`}>
          {!isFloatingBetweenComponents && title && <h2>{title}</h2>}
          <div className="input-wrapper" onBlur={() => closeDropdown()}>
            <ConditionalWrapper
              condition={prettyUrlTitle}
              wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
            >
              <form onSubmit={(e) => e.preventDefault()}>
                <input
                  className="search-input"
                  autoFocus={isFocused}
                  onChange={async (event) => {
                    setInputValue(event.target.value);
                    setSearchSuggestions(
                      await getSearchSuggestions(
                        3,
                        event.target.value,
                        microSitePath,
                        true,
                        100,
                        false
                      )
                    );
                  }}
                  placeholder={
                    isFloatingBetweenComponents ? 'Can we help you find something?' : 'Search'
                  }
                  type="text"
                  value={inputValue}
                />
                {inputValue && (
                  <button
                    type="button"
                    className="close-icon"
                    onClick={() => closeDropdownClearValue()}
                  >
                    <img src={CloseIcon} alt="Close" />
                  </button>
                )}
                <button
                  type="submit"
                  onClick={async () => {
                    if (redirectToSearch) {
                      goToRedirect(searchUrl, 'searchtext', inputValue);
                    } else {
                      closeDropdown();
                      onSearch(inputValue, 1);
                    }
                  }}
                >
                  <img src={SearchIcon} alt="Search" />
                </button>
              </form>
            </ConditionalWrapper>
            {dropdownOpen && (
              <div className="predictive-dropdown">
                {searchSuggestions.map((item) => (
                  <div className="dropdown-item" key={item.url}>
                    {item.url ? (
                      <a
                        href={item.url}
                        title="Predictive Item URL"
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <p dangerouslySetInnerHTML={{ __html: item.text }} />
                        <span className="url">{item.url}</span>
                      </a>
                    ) : (
                      <p dangerouslySetInnerHTML={{ __html: item.text }} />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {searchLinks && searchLinks.length > 0 && (
            <div className={`search-links ${colorScheme === 'light' ? 'primary' : 'light'}`}>
              {'E.g. '}
              {searchLinks.map((link, index) => (
                <span key={link.url}>
                  <a href={link.url} target={link.target}>
                    {link.name}
                  </a>
                  {index + 1 < searchLinks.length && ', '}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

WhiteLabelSearchBarComponent.defaultProps = {
  isFloatingBetweenComponents: false,
  isFocused: false,
  onSearch: () => {},
  prettyUrlTitle: '',
  query: '',
  redirectToSearch: true,
  searchLinks: [],
  searchUrl: '',
  title: '',
  microSitePath: ''
};

WhiteLabelSearchBarComponent.propTypes = {
  colorScheme: PropTypes.string.isRequired,
  isFloatingBetweenComponents: PropTypes.bool,
  isFocused: PropTypes.bool,
  onSearch: PropTypes.func,
  prettyUrlTitle: PropTypes.string,
  query: PropTypes.string,
  redirectToSearch: PropTypes.bool,
  searchLinks: PropTypes.arrayOf(PropTypes.object),
  searchUrl: PropTypes.string,
  title: PropTypes.string,
  microSitePath: PropTypes.string
};

export default WhiteLabelSearchBarComponent;
