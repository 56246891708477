import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import { useCssVariables } from '@/utilities';
import { getSectionClassNames } from '@/utilities/component-color';
import SimpleProduct from './SimpleProduct';

const SimpleProductsServicesComponent = ({
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  products,
  shortDescription,
  title,
  leftArrow,
  rightArrow,
  componentBackgroundColor
}) => {
  const sliderRef = useRef(null);
  const [isSlider, setIsSlider] = useState(false);
  const checkIfSlider = (slideCountThreshold) => () => {
    if (sliderRef.current) {
      setIsSlider(sliderRef.current.innerSlider.state.slideCount > slideCountThreshold);
    }
  };

  const ref = useRef(null);
  const slidesToShow = Math.min(4, products.length);
  const flexBasis = `${100 / slidesToShow}%`;
  useCssVariables(ref, { '--slide-flex-basis': flexBasis });

  const settings = {
    arrows: false,
    draggable: false,
    slidesToShow,
    slidesToScroll: 1,
    infinite: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          lazyLoad: true,
          onReInit: checkIfSlider(2)
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: true,
          lazyLoad: true,
          onReInit: checkIfSlider(1)
        }
      }
    ],
    onReInit: checkIfSlider(slidesToShow)
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <section className={getSectionClassNames(componentBackgroundColor)}>
      <div className="container-fluid">
        <div className="section-simple-products-services" ref={ref}>
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <div className="content">
              <GlobalComponentContainer
                {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
              />
            </div>
            <div className="simple-tiles">
              {isSlider && (
                <span className="navigation" id="previous-arrow">
                  <button className="btn-no-style" type="button" onClick={prevSlide}>
                    <img src={leftArrow} alt="Back Arrow" />
                  </button>
                </span>
              )}
              <Slider ref={sliderRef} {...settings}>
                {products.map((product, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SimpleProduct {...product} key={index} />
                ))}
              </Slider>
              {isSlider && (
                <span className="navigation" id="next-arrow">
                  <button className="btn-no-style" type="button" onClick={nextSlide}>
                    <img src={rightArrow} alt="Next Arrow" />
                  </button>
                </span>
              )}
            </div>
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

SimpleProductsServicesComponent.defaultProps = {
  parentPageTitle: '',
  parentPageUrl: '',
  prettyUrlTitle: '',
  leftArrow: '',
  rightArrow: '',
  products: [],
  componentBackgroundColor: 'white'
};

SimpleProductsServicesComponent.propTypes = {
  parentPageName: PropTypes.string.isRequired,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  shortDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  leftArrow: PropTypes.string,
  rightArrow: PropTypes.string,
  componentBackgroundColor: PropTypes.string
};

export default SimpleProductsServicesComponent;
