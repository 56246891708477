import { apiGetService, apiPostService } from './baseApi.service';

export function newsroomSearch(filters, itemsPerPage, page, isBusinessUnit) {
  return (async () => {
    let url = `/api/newsroom/search?page=${page}&itemsPerPage=${itemsPerPage}&isBusinessUnit=${isBusinessUnit}`;
    let filtersList = '';

    if (filters) {
      filtersList = filters.map((filter) => `&${filter.type}=${filter.values.join()}`).join('');
      url += `${filtersList}`;
    }

    return apiGetService(url);
  })();
}

export function submitClick(item) {
  return (async () => apiPostService('/api/search/submitClick', item))();
}
