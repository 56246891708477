import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import classNames from 'classnames';
import { useCssVariables } from '@/utilities';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import logConversion from '@/services/conversion.service';
import { getSectionClassNames } from '@/utilities/component-color';

import { sizedImageShape } from '@/types';

const ConversionLinkComponent = ({
  alignment,
  altText,
  contentTitle,
  conversionIdentifier,
  ctaButtonText,
  ctaButtonTarget,
  ctaButtonColor,
  ctaOverrideButtonColor,
  ctaButtonBackgroundColor,
  ctaButtonTextColor,
  ctaButtonUrl,
  description,
  image,
  imagePosition,
  imageUrl,
  imageUrlTarget,
  prettyUrlTitle,
  title,
  componentBackgroundColor
}) => {
  const logConversionAndRoute = () => {
    logConversion(conversionIdentifier);

    if (ctaButtonTarget === '_self' || ctaButtonTarget === '') {
      window.location.href = ctaButtonUrl;
    } else {
      window.open(ctaButtonUrl, '_blank');
    }
  };
  const hasImage = image && image.url;

  const conversionLinkCTAComponentRef = useRef(null);
  const safeCtaButtonColor = ctaButtonColor.toLowerCase();
  const [cssButtonColor, setCssButtonColor] = useState(ctaButtonBackgroundColor);
  const [cssButtonTextColor, setCssButtonTextColor] = useState(ctaButtonTextColor);

  useEffect(() => {
    if (ctaOverrideButtonColor) return;

    setCssButtonColor(`var(--cta-main-${safeCtaButtonColor})`);

    if (safeCtaButtonColor === 'pink' || safeCtaButtonColor === 'blue') {
      setCssButtonTextColor('var(--cta-text-white)');
    } else {
      setCssButtonTextColor('var(--cta-text-black)');
    }
  }, [ctaOverrideButtonColor, safeCtaButtonColor]);

  useCssVariables(conversionLinkCTAComponentRef, {
    '--text-color': 'inherit',
    '--cta-background-color': cssButtonColor,
    '--cta-text-color': cssButtonTextColor
  });

  return (
    <Element name={prettyUrlTitle}>
      <section
        className={`${getSectionClassNames(
          componentBackgroundColor
        )} conversion-link-component section-gutter`}
      >
        <div className="container-fluid">
          <div className="general-text-component">
            <div className="container-fluid">
              {contentTitle && <h2>{contentTitle}</h2>}
              <div className={classNames('description-container', imagePosition || 'left')}>
                {description && (
                  <div className="content" dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {hasImage && (
                  <div className="image">
                    <ConditionalWrapper
                      condition={imageUrl}
                      wrapper={(children) => (
                        <a
                          href={imageUrl}
                          target={imageUrlTarget}
                          title={`Link for ${altText || title}`}
                        >
                          {children}
                        </a>
                      )}
                    >
                      <LazyLoadImg src={image} alt={altText || title} />
                    </ConditionalWrapper>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="quick-cta" ref={conversionLinkCTAComponentRef}>
            <div className={`conversion container-fluid ${alignment}`}>
              <div className={classNames('wrapper', { inner: title })}>
                {title && <div className="content" dangerouslySetInnerHTML={{ __html: title }} />}

                <div className="cta">
                  <div className="btn-container">
                    <button className="btn" onClick={() => logConversionAndRoute()} type="button">
                      {ctaButtonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

ConversionLinkComponent.defaultProps = {
  altText: null,
  contentTitle: '',
  ctaButtonTarget: '',
  ctaOverrideButtonColor: false,
  ctaButtonBackgroundColor: '',
  ctaButtonTextColor: '',
  description: '',
  image: {},
  imageUrl: '',
  imageUrlTarget: '',
  prettyUrlTitle: '',
  componentBackgroundColor: 'white'
};

ConversionLinkComponent.propTypes = {
  altText: PropTypes.string,
  alignment: PropTypes.string.isRequired,
  contentTitle: PropTypes.string,
  conversionIdentifier: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string.isRequired,
  ctaButtonTarget: PropTypes.string,
  ctaButtonColor: PropTypes.string.isRequired,
  ctaOverrideButtonColor: PropTypes.bool,
  ctaButtonBackgroundColor: PropTypes.string,
  ctaButtonTextColor: PropTypes.string,
  ctaButtonUrl: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: sizedImageShape,
  imagePosition: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageUrlTarget: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  componentBackgroundColor: PropTypes.string
};

export default ConversionLinkComponent;
