import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import DateFormat from 'dateformat';
import cookie from 'react-cookies';
import classNames from 'classnames';
import { useCssVariables } from '@/utilities';

const Alert = ({
  allowDismissal,
  color,
  count,
  dismissTiming,
  endDate,
  fullDetailsUrl,
  fullDetailsUrlTarget,
  hasDate,
  hasMultipleAlerts,
  icon,
  index,
  message,
  filterAlertsList,
  startDate,
  textColor,
  title
}) => {
  const string = message;
  const lengthMobile = 100;
  const length = 180;

  const trimmedStringMobile =
    string.length > lengthMobile
      ? `${string.substring(0, lengthMobile - 3)}...`
      : string.substring(0, lengthMobile);
  const trimmedString =
    string.length > length ? `${string.substring(0, length - 3)}...` : string.substring(0, length);

  const newStartDate = new Date(startDate);
  const formattedStartDate = DateFormat(newStartDate, 'm/d/yyyy');
  const newEndDate = new Date(endDate);
  const formattedEndDate = DateFormat(newEndDate, 'm/d/yyyy');

  const onDismiss = (cookieName) => {
    cookie.save(cookieName, null, { path: '/', maxAge: dismissTiming * 3600 });
  };

  const alertRef = useRef(null);

  useCssVariables(alertRef, {
    '--text-color': `#${textColor}`,
    '--background-color': `#${color}`
  });

  return (
    <div className="alert" ref={alertRef}>
      <div className="container-fluid">
        <div
          className={classNames('inner', {
            multiple: hasMultipleAlerts,
            dismissible: allowDismissal
          })}
        >
          <img src={icon} className="icon" alt={`${title} icon`} />
          <div className="message">
            {hasMultipleAlerts && <span className="count">{`Alert ${index + 1}/${count}: `}</span>}
            <span
              className="effective-date"
              dangerouslySetInnerHTML={{
                __html: `${
                  hasDate
                    ? `Effective ${formattedStartDate} until ${formattedEndDate}<span class="br-xl-inline">: </span>`
                    : ''
                }${fullDetailsUrl && ` <a href=${fullDetailsUrl}>View Full Details</a>`}`
              }}
            />
            <span className="message-text">
              <span className="br-xl-inline">{trimmedString}</span>
              <span className="br-sm-inline">{trimmedStringMobile}</span>
              {fullDetailsUrl && (
                <span
                  className="link"
                  dangerouslySetInnerHTML={{
                    __html: ` <a href=${fullDetailsUrl} target=${fullDetailsUrlTarget}>View Full Details</a>`
                  }}
                />
              )}
            </span>
          </div>
          {allowDismissal && (
            <button
              type="button"
              onClick={() => {
                filterAlertsList(index);
                onDismiss(title);
              }}
              className="btn-no-style"
            >
              Dismiss
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Alert.defaultProps = {
  filterAlertsList: () => {},
  fullDetailsUrlTarget: '_self'
};

Alert.propTypes = {
  allowDismissal: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  dismissTiming: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  fullDetailsUrl: PropTypes.string.isRequired,
  fullDetailsUrlTarget: PropTypes.string,
  hasDate: PropTypes.bool.isRequired,
  hasMultipleAlerts: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  filterAlertsList: PropTypes.func,
  startDate: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Alert;
