import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { video } from '@/types';
import { useCssVariables } from '@/utilities';
import PlayIcon from './PlayIcon';

const VideoItemComponent = ({ videoItem, gridClass, dynamicImage, selectContent }) => {
  const dynamicImageContainerRef = useRef(null);

  useCssVariables(dynamicImageContainerRef, {
    '--background-image': `url("${dynamicImage}")`
  });

  return (
    <div ref={dynamicImageContainerRef} className={classNames('item', gridClass)}>
      <button
        className="btn-no-style overlay"
        type="button"
        onClick={() => selectContent(videoItem.guid)}
      >
        {videoItem.videoUrl && <PlayIcon iconColor={videoItem.playIconColor} />}
        <div className="video-content">
          <div className="video-title" dangerouslySetInnerHTML={{ __html: videoItem.title }} />
          <div
            className="video-sub-title"
            dangerouslySetInnerHTML={{ __html: videoItem.subTitle }}
          />
        </div>
      </button>
    </div>
  );
};

VideoItemComponent.defaultProps = {
  videoItem: {},
  gridClass: ''
};

VideoItemComponent.propTypes = {
  gridClass: PropTypes.string,
  dynamicImage: PropTypes.string.isRequired,
  selectContent: PropTypes.func.isRequired,
  videoItem: video
};

export default VideoItemComponent;
