import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import arrowUp from 'images/icons/layout/Arrow-Up-Purple.svg';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';
import BusinessUnitDetail from './BusinessUnitDetail';

const MobileMenuItem = ({
  businessUnitMenuItems,
  children,
  isActive,
  name,
  showMenuItemsAsTiles,
  url
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasBusinessUnits = businessUnitMenuItems && !!businessUnitMenuItems.length;
  const hasValidSubLinks = !!(children && children.length);
  const hasLink = !!url;
  const hasSubMenuItems = hasValidSubLinks || hasBusinessUnits;

  const toggleSubMenu = () => {
    if (hasSubMenuItems) {
      setIsExpanded(!isExpanded);
    }
  };

  const onClickOfItemWithLink = () => {
    if (hasSubMenuItems) {
      toggleSubMenu();

      if (isExpanded) {
        setIsExpanded(true);
        window.location.href = url;
      }
    } else {
      window.location.href = url;
    }
  };

  const buttonOnClick = hasLink ? onClickOfItemWithLink : toggleSubMenu;

  return (
    <li className={`br-sm${isActive || isExpanded ? ' active' : ''}`}>
      <button onClick={buttonOnClick} className="btn-no-style" type="button">
        <span
          className={`btn-contain ${hasLink && 'has-link'} ${!hasSubMenuItems && 'no-children'}`}
        >
          {name}
        </span>
        {hasSubMenuItems && (
          <img src={isExpanded ? arrowUp : arrowDown} className="chevron" alt="Chevron Arrow" />
        )}
      </button>
      <AnimateHeight duration={500} height={isExpanded ? 'auto' : 0} animateOpacity>
        <div className="expansion">
          <ul className="business-units secondary-navigation">
            {hasBusinessUnits &&
              businessUnitMenuItems.map((businessUnit) =>
                showMenuItemsAsTiles ? (
                  <BusinessUnitDetail key={businessUnit.guid} {...businessUnit} />
                ) : (
                  <li key={businessUnit.guid}>
                    <a href={businessUnit.landingPageUrl} title={`Go To ${businessUnit.name}`}>
                      {businessUnit.title}
                    </a>
                  </li>
                )
              )}

            {hasValidSubLinks &&
              !hasBusinessUnits &&
              children.map((link) => (
                <li key={link.name}>
                  <a href={link.url} title={`Go To ${link.name}`} target={link.target}>
                    {link.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </AnimateHeight>
    </li>
  );
};

MobileMenuItem.defaultProps = {
  businessUnitMenuItems: [],
  children: [],
  isActive: false,
  name: '',
  showMenuItemsAsTiles: false,
  url: ''
};

MobileMenuItem.propTypes = {
  businessUnitMenuItems: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.arrayOf(PropTypes.object),
  isActive: PropTypes.bool,
  name: PropTypes.string,
  showMenuItemsAsTiles: PropTypes.bool,
  url: PropTypes.string
};

export default MobileMenuItem;
