import React from 'react';
import PropTypes from 'prop-types';

import AnchorWrapper from './AnchorWrapper';

const FooterMenuItem = ({ children, icon, isMenuHeader, name, showIconOnly, target, url }) => {
  const hasChildren = !!(children && children.length);

  return (
    <>
      <li className={showIconOnly ? 'inline social-item' : ''}>
        <AnchorWrapper url={url} target={target} name={name}>
          {icon ? (
            <>
              <img className={showIconOnly ? 'social-item-image' : ''} src={icon} alt={name} />
              {!showIconOnly && <span className={isMenuHeader ? 'menu-header' : ''}>{name}</span>}
            </>
          ) : (
            <span className={isMenuHeader ? 'menu-header' : ''}>{name}</span>
          )}
        </AnchorWrapper>
      </li>
      {hasChildren &&
        children.map((childItem) => <FooterMenuItem key={childItem.name} {...childItem} />)}
    </>
  );
};

FooterMenuItem.defaultProps = {
  children: [],
  icon: '',
  isMenuHeader: false,
  name: '',
  showIconOnly: false,
  target: '',
  url: ''
};

FooterMenuItem.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  icon: PropTypes.string,
  isMenuHeader: PropTypes.bool,
  name: PropTypes.string,
  showIconOnly: PropTypes.bool,
  target: PropTypes.string,
  url: PropTypes.string
};

export default FooterMenuItem;
