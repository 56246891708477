import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import Slider from 'react-slick';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import { getSectionClassNames } from '@/utilities/component-color';

import {
  fullWidthImageCarouselSlideShape,
  globalComponentProperties,
  globalComponentDefaults
} from '@/types';

import DesktopAndTabletContainer from '@/components/shared/DesktopAndTabletContainer';
import MobileContainer from '@/components/shared/MobileContainer';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import SliderArrow from '@/components/shared/slick/SliderArrow';

const FullWidthImageCarouselComponent = ({
  isInfinite,
  isAutoplay,
  autoplaySpeed,
  slides,
  parentPageTitle,
  parentPageUrl,
  shortDescription,
  prettyUrlTitle,
  title,
  leftArrow,
  rightArrow,
  componentBackgroundColor
}) => {
  const sliderSettings = {
    infinite: isInfinite,
    arrows: true,
    autoplay: isAutoplay,
    autoplaySpeed: autoplaySpeed * 1000, // convert to milliseconds
    dots: true,
    prevArrow: (
      <SliderArrow
        className="slick-arrow slick-prev"
        to="prev"
        img={leftArrow}
        checkIfHidden={(currentSlide) => !isInfinite && currentSlide === 0}
      />
    ),
    nextArrow: (
      <SliderArrow
        className="slick-arrow slick-next"
        to="next"
        img={rightArrow}
        checkIfHidden={(currentSlide) => !isInfinite && currentSlide === slides.length - 1}
      />
    )
  };

  return (
    <div className={getSectionClassNames(componentBackgroundColor)}>
      <div className="container-fluid">
        <div className="section-gutter">
          <section className="full-width-image-carousel">
            <ConditionalWrapper
              condition={prettyUrlTitle}
              wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
            >
              <GlobalComponentContainer
                parentPageTitle={parentPageTitle}
                parentPageUrl={parentPageUrl}
                shortDescription={shortDescription}
                title={title}
              />
              <Slider {...sliderSettings}>
                {slides.map((slide, index) => (
                  // We have no stable ID to use for this array.
                  // More information: https://reactjs.org/docs/lists-and-keys.html#keys
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="wrapper" key={index}>
                    <MobileContainer>
                      <LazyLoadImg
                        id={index}
                        src={slide.mobileImage}
                        alt={slide.mobileImageAltText}
                      />
                    </MobileContainer>
                    <DesktopAndTabletContainer>
                      <LazyLoadImg
                        id={index}
                        src={slide.desktopImage}
                        alt={slide.desktopImageAltText}
                      />
                    </DesktopAndTabletContainer>
                  </div>
                ))}
              </Slider>
            </ConditionalWrapper>
          </section>
        </div>
      </div>
    </div>
  );
};

FullWidthImageCarouselComponent.defaultProps = {
  isInfinite: false,
  leftArrow: '',
  rightArrow: '',
  slides: [],
  componentBackgroundColor: 'white',
  ...globalComponentDefaults
};

FullWidthImageCarouselComponent.propTypes = {
  isInfinite: PropTypes.bool,
  leftArrow: PropTypes.string,
  rightArrow: PropTypes.string,
  slides: PropTypes.arrayOf(fullWidthImageCarouselSlideShape),
  componentBackgroundColor: PropTypes.string,
  ...globalComponentProperties
};

export default FullWidthImageCarouselComponent;
