import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useCssVariables } from '@/utilities';

const CtaButton = ({
  children,
  target,
  path,
  buttonColor,
  overrideButtonColor,
  textColor,
  backgroundColor,
  className
}) => {
  const ctaButtonRef = useRef(null);

  const colorScheme = buttonColor.toLowerCase();

  const [cssBackgroundColor, setCssBackgroundColor] = useState(backgroundColor);
  const [cssTextColor, setCssTextColor] = useState(textColor);

  useEffect(() => {
    if (overrideButtonColor) return;

    setCssBackgroundColor(`var(--hero-main-${colorScheme})`);
    if (colorScheme === 'pink' || colorScheme === 'blue') {
      setCssTextColor(`var(--hero-text-white)`);
    } else {
      setCssTextColor(`var(--hero-text-black)`);
    }
  }, [colorScheme, overrideButtonColor]);

  useCssVariables(ctaButtonRef, {
    '--cta-button-text-color': cssTextColor,
    '--cta-button-background-color': cssBackgroundColor
  });

  return (
    <div className={classNames('btn-container', className)} ref={ctaButtonRef}>
      <a className="btn" href={path} target={target}>
        {children}
      </a>
    </div>
  );
};

CtaButton.defaultProps = {
  children: null,
  className: '',
  overrideButtonColor: false
};

CtaButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.string]),
  target: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonColor: PropTypes.string.isRequired,
  overrideButtonColor: PropTypes.bool
};

export default CtaButton;
