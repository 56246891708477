import React from 'react';
import PropTypes from 'prop-types';

const BusinessUnitItem = ({
  getNewsroomData,
  isActive,
  modifyFilters,
  setFilters,
  title,
  value
}) => (
  <li>
    <button
      onClick={() => {
        const filters = modifyFilters('businessunittitle', value, true);
        setFilters(filters);
        getNewsroomData(1, filters, false);
      }}
      value={value}
      type="button"
      className={`btn-filter ${isActive ? 'active' : ''}`}
    >
      {title}
    </button>
  </li>
);

BusinessUnitItem.defaultProps = {
  getNewsroomData: () => {},
  isActive: false,
  modifyFilters: () => {},
  setFilters: () => {},
  title: '',
  value: ''
};

BusinessUnitItem.propTypes = {
  getNewsroomData: PropTypes.func,
  isActive: PropTypes.bool,
  modifyFilters: PropTypes.func,
  setFilters: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string
};

export default BusinessUnitItem;
