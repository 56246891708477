import React from 'react';

import { whiteLabelSmallHeroBanner } from '@/types';
import WhiteLabelSmallHeroBanner from './WhiteLabelSmallHeroBanner';

const WhiteLabelSmallHeroBannerWidget = ({ heroSlide }) => (
  <section className="hero hero-small">
    <WhiteLabelSmallHeroBanner heroSlide={heroSlide} />
  </section>
);

WhiteLabelSmallHeroBannerWidget.propTypes = {
  heroSlide: whiteLabelSmallHeroBanner.isRequired
};

export default WhiteLabelSmallHeroBannerWidget;
