import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import classNames from 'classnames';
import { useCssVariables } from '@/utilities';

const QuickCtaComponent = ({
  prettyUrlTitle,
  title,
  backgroundColor,
  overrideBackgroundColor,
  customBackgroundColor,
  customTitleTextColor,
  alignment,
  ctaButtonText,
  ctaButtonUrl,
  ctaButtonTarget,
  ctaButtonColor,
  ctaOverrideButtonColor,
  ctaButtonBackgroundColor,
  ctaButtonTextColor
}) => {
  const quickCtaRef = useRef(null);

  const safeBackgroundColor = backgroundColor.toLowerCase();
  const safeCtaButtonColor = ctaButtonColor.toLowerCase();
  const sectionOffset = safeBackgroundColor === 'offset' && overrideBackgroundColor === false;

  const [cssBackgroundColor, setCssBackgroundColor] = useState(customBackgroundColor);
  const [cssTextColor, setCssTextColor] = useState(customTitleTextColor);
  const [cssButtonColor, setCssButtonColor] = useState(ctaButtonBackgroundColor);
  const [cssButtonTextColor, setCssButtonTextColor] = useState(ctaButtonTextColor);

  const setTextColor = (color, setter) => {
    const useWhite = color === 'pink' || color === 'blue';
    if (useWhite) {
      setter('var(--cta-text-white)');
      return;
    }
    setter('var(--cta-text-black)');
  };

  useEffect(() => {
    if (overrideBackgroundColor) return;

    setCssBackgroundColor(`var(--cta-main-${safeBackgroundColor})`);
    setTextColor(safeBackgroundColor, setCssTextColor);
  }, [overrideBackgroundColor, safeBackgroundColor]);

  useEffect(() => {
    if (ctaOverrideButtonColor) return;

    setCssButtonColor(`var(--cta-main-${safeCtaButtonColor})`);
    setTextColor(safeCtaButtonColor, setCssButtonTextColor);
  }, [ctaOverrideButtonColor, safeCtaButtonColor]);

  useCssVariables(quickCtaRef, {
    '--background-color': cssBackgroundColor,
    '--text-color': cssTextColor,
    '--cta-background-color': cssButtonColor,
    '--cta-text-color': cssButtonTextColor
  });

  return (
    <Element name={prettyUrlTitle}>
      <section
        className={classNames('quick-cta', sectionOffset ? 'section-offset' : 'section-white')}
        ref={quickCtaRef}
      >
        <div className={`container-fluid ${alignment}`}>
          <div className="inner wrapper">
            <div className="content" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="cta">
              <div className="btn-container">
                <a className="btn" href={ctaButtonUrl} target={ctaButtonTarget}>
                  {ctaButtonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

QuickCtaComponent.defaultProps = {
  prettyUrlTitle: '',
  overrideBackgroundColor: false,
  customBackgroundColor: 'white',
  customTitleTextColor: 'black',
  ctaButtonTarget: '',
  ctaOverrideButtonColor: false,
  ctaButtonBackgroundColor: 'white',
  ctaButtonTextColor: 'black'
};

QuickCtaComponent.propTypes = {
  prettyUrlTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  overrideBackgroundColor: PropTypes.bool,
  customBackgroundColor: PropTypes.string,
  customTitleTextColor: PropTypes.string,
  alignment: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string.isRequired,
  ctaButtonUrl: PropTypes.string.isRequired,
  ctaButtonTarget: PropTypes.string,
  ctaButtonColor: PropTypes.string.isRequired,
  ctaOverrideButtonColor: PropTypes.bool,
  ctaButtonBackgroundColor: PropTypes.string,
  ctaButtonTextColor: PropTypes.string
};

export default QuickCtaComponent;
