import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import close from 'images/icons/layout/Close-White.svg';

const VideoModal = ({ isActive, selectFunction, title, videoUrl }) => {
  ReactModal.setAppElement('#wrapper');

  return (
    <ReactModal
      isOpen={isActive}
      className="modal"
      overlayClassName="modal-overlay"
      onRequestClose={() => selectFunction(null)}
    >
      <div className="container-fluid">
        <button type="button" onClick={() => selectFunction(null)} className="close-button">
          <img src={close} alt="Close" />
        </button>
        <div className="iframe-container">
          <iframe src={`${videoUrl}?autoplay=1`} title={title} allowFullScreen allow="autoplay" />
        </div>
      </div>
    </ReactModal>
  );
};

VideoModal.defaultProps = {
  isActive: false,
  selectFunction: () => {},
  title: '',
  videoUrl: ''
};

VideoModal.propTypes = {
  isActive: PropTypes.bool,
  selectFunction: PropTypes.func,
  title: PropTypes.string,
  videoUrl: PropTypes.string
};

export default VideoModal;
