import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import { getSectionClassNames } from '@/utilities/component-color';

const HtmlSnippetComponent = ({
  hasContentDivider,
  html,
  prettyUrlTitle,
  componentBackgroundColor
}) => {
  const content = html.replaceAll('<\\script', '<script').replaceAll('<\\/script>', '</script>');
  return (
    <div className={getSectionClassNames(componentBackgroundColor)}>
      <ConditionalWrapper
        condition={prettyUrlTitle}
        wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
      >
        <div className="container-fluid snippet-section">
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {hasContentDivider && <ContentDivider />}
        </div>
      </ConditionalWrapper>
    </div>
  );
};
HtmlSnippetComponent.defaultProps = {
  hasContentDivider: false,
  prettyUrlTitle: '',
  componentBackgroundColor: 'white'
};

HtmlSnippetComponent.propTypes = {
  hasContentDivider: PropTypes.bool,
  html: PropTypes.string.isRequired,
  prettyUrlTitle: PropTypes.string,
  componentBackgroundColor: PropTypes.string
};

export default HtmlSnippetComponent;
