import React, { Fragment, useEffect } from 'react';
import UserAgentParser from 'ua-parser-js';

export const isMobile = (userAgent) =>
  !!(userAgent && UserAgentParser(userAgent).device.type === 'mobile');

export const isTablet = (userAgent) =>
  !!(userAgent && UserAgentParser(userAgent).device.type === 'tablet');

export const isTabletOrMobile = (userAgent) => isMobile(userAgent) || isTablet(userAgent);

export const isDesktop = (userAgent) => !isTabletOrMobile(userAgent);

export const isDesktopOrTablet = (userAgent) => isDesktop(userAgent) || isTablet(userAgent);

export const isRunningOnServer = () => !(typeof window !== 'undefined' && window.document);

export const newLineToBreak = (text) => {
  const newLine = '\r\n';

  if (typeof text === 'number') {
    return text;
  }
  if (typeof text !== 'string') {
    return '';
  }

  return text.split(newLine).map((line, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={key}>
      {line}
      <br />
    </Fragment>
  ));
};

/**
 * Dynamically sets CSS variables on the given element.
 * @param {React.MutableRefObject<any>} ref The element ref that you wish to change the variables of.
 * @param {Record<string, string>} values An object with key-value pairs of variable names and value.
 */
export const useCssVariables = (ref, values) => {
  useEffect(() => {
    if (!ref.current) return;

    Object.entries(values).forEach(([variableName, value]) => {
      if (!value) return;

      ref.current.style.setProperty(variableName, value);
    });
  }, [ref, values]);
};
