import React, { useEffect, useRef, useState } from 'react';

import { smallHeroSlide } from '@/types';
import CtaButton from '@/components/shared/ctaButton/CtaButton';
import { useCssVariables } from '@/utilities';

const SmallHeroSlide = ({ heroSlide }) => {
  const colorWrapperRef = useRef(null);

  const { overrideColorScheme, ctaButton } = heroSlide;
  const { hasCtaButton, hasSecondCtaButton } = ctaButton;
  const colorScheme = heroSlide.colorScheme.toLowerCase();

  const [cssBackgroundColor, setCssBackgroundColor] = useState(heroSlide.backgroundColor);
  const [cssTextColor, setCssTextColor] = useState(heroSlide.textColor);

  useEffect(() => {
    if (overrideColorScheme) return;

    setCssBackgroundColor(`var(--hero-main-${colorScheme})`);
    if (colorScheme === 'pink' || colorScheme === 'blue') {
      setCssTextColor(`var(--hero-text-white)`);
    } else {
      setCssTextColor(`var(--hero-text-black)`);
    }
  }, [colorScheme, overrideColorScheme]);

  useCssVariables(colorWrapperRef, {
    '--background-color': cssBackgroundColor,
    '--text-color': cssTextColor
  });

  return (
    <div className="item">
      <div ref={colorWrapperRef} className="color-wrapper">
        <div className="content">
          <div className="container-fluid">
            <div className="inner-content">
              <p
                className="eyebrow-text"
                dangerouslySetInnerHTML={{ __html: heroSlide.eyebrowText }}
              />
              <h1>
                <span dangerouslySetInnerHTML={{ __html: heroSlide.title }} />
              </h1>
            </div>
            {hasCtaButton && (
              <div className="inner-cta button">
                <CtaButton {...ctaButton}>{ctaButton.text}</CtaButton>
                {hasSecondCtaButton && (
                  <CtaButton
                    className="second"
                    backgroundColor={ctaButton.secondBackgroundColor}
                    path={ctaButton.secondPath}
                    target={ctaButton.secondTarget}
                    textColor={ctaButton.secondTextColor}
                    buttonColor={ctaButton.secondButtonColor}
                    overrideButtonColor={ctaButton.secondOverrideButtonColor}
                  >
                    {ctaButton.secondText}
                  </CtaButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SmallHeroSlide.propTypes = {
  heroSlide: smallHeroSlide.isRequired
};

export default SmallHeroSlide;
