import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ArrowLeft from 'images/icons/layout/Arrow-Left-White.png';
import ArrowRight from 'images/icons/layout/Arrow-Right-White.png';
import { useCssVariables } from '@/utilities';
import Alert from './Alert';

const Alerts = ({ alerts }) => {
  const sliderSettings = {
    adaptiveHeight: true,
    arrows: true,
    autoplay: false,
    dots: false
  };
  const [alertsList, setAlertsList] = useState(alerts);

  const hasMultipleAlerts = alertsList.length > 1;
  const alertsCount = alertsList.length;

  const filterAlertsList = (index) => {
    const array = [...alertsList];

    if (index > -1) {
      array.splice(index, 1);
    }

    if (array.length === 0) {
      window.location.reload();
    }

    setAlertsList(array);
  };

  const alertsRef = useRef(null);

  useCssVariables(alertsRef, {
    '--arrow-left': `url('${ArrowLeft}')`,
    '--arrow-right': `url('${ArrowRight}')`
  });

  return (
    <div className="section-alerts" ref={alertsRef}>
      <Slider {...sliderSettings}>
        {alertsList &&
          alertsList.map((alert, index) => (
            <Alert
              {...alert}
              count={alertsCount}
              filterAlertsList={filterAlertsList}
              hasMultipleAlerts={hasMultipleAlerts}
              index={index}
              key={alert.title}
            />
          ))}
      </Slider>
    </div>
  );
};

Alerts.defaultProps = {
  alerts: []
};

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object)
};

export default Alerts;
