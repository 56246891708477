import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import { searchHeaderShape } from '@/types';
import StateSelector from './StateSelector';

const StateSelectorComponent = ({
  initialState,
  searchHeader,
  states,
  showContentDividerInitialState,
  showDataTableContentDivider
}) => {
  const [showContentDivider, setShowContentDivider] = useState(showContentDividerInitialState);

  if (!states) return null;

  return (
    <div className={classNames('container-fluid', { 'has-divider': showContentDivider })}>
      <div className="section-gutter">
        <StateSelector
          {...{
            initialState,
            searchHeader,
            states,
            setShowContentDivider,
            showDataTableContentDivider
          }}
        />
      </div>
      {showContentDivider && <ContentDivider />}
    </div>
  );
};

StateSelectorComponent.defaultProps = {
  initialState: '',
  showContentDividerInitialState: true,
  showDataTableContentDivider: true
};

StateSelectorComponent.propTypes = {
  initialState: PropTypes.string,
  searchHeader: searchHeaderShape.isRequired,
  showContentDividerInitialState: PropTypes.bool,
  showDataTableContentDivider: PropTypes.bool,
  states: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default StateSelectorComponent;
