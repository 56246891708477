import React from 'react';
import PropTypes from 'prop-types';

const AnchorWrapper = ({ children, name, target, url }) =>
  url ? (
    <a href={url} {...(target && { target })} title={`Go to ${name}`}>
      {children}
    </a>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );

AnchorWrapper.defaultProps = {
  name: '',
  target: '',
  url: ''
};

AnchorWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  name: PropTypes.string,
  target: PropTypes.string,
  url: PropTypes.string
};

export default AnchorWrapper;
