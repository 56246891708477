import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { newLineToBreak } from '@/utilities';
import { globalImage, ccpaBannerShape } from '@/types';
import CCPABanner from '@/components/shared/ccpaBanner/CCPABanner';
import FooterMenuItem from './shared/FooterMenuItem';
import LegalMenuItem from './shared/LegalMenuItem';

const Footer = ({
  ccpaBanner,
  copyright,
  corporateAddress,
  footerLegalText,
  footerMenuItems,
  globalImages,
  hideFooterMenu,
  isMobileNavigation,
  legalMenuItems,
  isWhiteLabel
}) => {
  const mobileFooterMenuItems =
    footerMenuItems && footerMenuItems.filter((item) => !!item.isVisibleOnMobileNavigation);

  const menuItems = () => (isMobileNavigation ? mobileFooterMenuItems : footerMenuItems);

  const [hasCCPABanner, setHasCCPABanner] = useState(ccpaBanner);
  return (
    <>
      <div className="container-fluid">
        <div className={`footer-wrap ${isWhiteLabel ? 'white-label' : ''}`}>
          {!isMobileNavigation && (!hideFooterMenu || isWhiteLabel) && (
            <div className="col-lg-3 logo-wrapper">
              <div className="logo">
                <a href="/" title="Go to Home">
                  <img
                    alt="HealthBenefits Logo"
                    src={globalImages.logoInverse && globalImages.logoInverse.directUrl}
                  />
                </a>
              </div>
            </div>
          )}
          {!hideFooterMenu &&
            menuItems() &&
            menuItems().map((item, index) => (
              <div key={item.name} className="col-lg-3">
                <ul>
                  <FooterMenuItem {...item} />
                  {index === footerMenuItems.length - 1 && !isMobileNavigation && (
                    <p className="address">
                      {newLineToBreak(corporateAddress)}
                      {copyright}
                    </p>
                  )}
                </ul>
              </div>
            ))}
          <div className={`col subfooter subfooter-links ${hideFooterMenu ? ' no-items' : ''}`}>
            {!isWhiteLabel &&
              legalMenuItems &&
              legalMenuItems.map((item) => <LegalMenuItem key={item.name} {...item} />)}
            <div className="legal-text" dangerouslySetInnerHTML={{ __html: footerLegalText }} />
          </div>
        </div>
      </div>
      {hasCCPABanner && <CCPABanner ccpaBanner={ccpaBanner} setHasCCPABanner={setHasCCPABanner} />}
    </>
  );
};

Footer.defaultProps = {
  ccpaBanner: {},
  footerLegalText: '',
  hideFooterMenu: false,
  isWhiteLabel: false
};

Footer.propTypes = {
  ccpaBanner: ccpaBannerShape,
  copyright: PropTypes.string.isRequired,
  corporateAddress: PropTypes.string.isRequired,
  footerLegalText: PropTypes.string,
  footerMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalImages: globalImage.isRequired,
  hideFooterMenu: PropTypes.bool,
  isMobileNavigation: PropTypes.bool.isRequired,
  legalMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isWhiteLabel: PropTypes.bool
};

export default Footer;
