import { createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { isRunningOnServer } from '@/utilities';
import middleware from './middleware';
import reducer from './reducers';

const APP_STATE_STORE = 'APP_STATE_STORE';

const getStore = () => {
  if (isRunningOnServer()) {
    return createStore(reducer, {}, middleware);
  }
  if (!window[APP_STATE_STORE]) {
    window[APP_STATE_STORE] = createStore(reducer, {}, composeWithDevTools(middleware));
  }
  return window[APP_STATE_STORE];
};

export default getStore;
