import React from 'react';

import { smallHeroSlide } from '@/types';
import SmallHeroSlide from './SmallHeroSlide';

const SmallHeroWidget = ({ heroSlide }) => (
  <section className="hero hero-small">
    <SmallHeroSlide heroSlide={heroSlide} />
  </section>
);

SmallHeroWidget.propTypes = {
  heroSlide: smallHeroSlide.isRequired
};

export default SmallHeroWidget;
