import React from 'react';
import PropTypes from 'prop-types';

import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import { sizedImageShape } from '@/types';

const Highlight = ({ description, icon, link, target, title }) => (
  <div className="highlight">
    <LazyLoadImg src={icon} alt="highlight icon" />
    <h3>
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </h3>
    {description && <h4 dangerouslySetInnerHTML={{ __html: description }} />}
    {link && (
      <a href={link} {...(target && { target })}>
        Read More
      </a>
    )}
  </div>
);

Highlight.defaultProps = {
  description: null,
  link: null,
  target: null,
  title: null
};

Highlight.propTypes = {
  description: PropTypes.string,
  icon: sizedImageShape.isRequired,
  link: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string
};

export default Highlight;
