import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import classNames from 'classnames';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import { getSectionClassNames } from '@/utilities/component-color';
import { useCssVariables } from '@/utilities';
import Highlight from './Highlight';

const HighlightsComponent = ({
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  hasContentDivider,
  highlights,
  componentBackgroundColor
}) => {
  const flexPercent = highlights?.length ? 100 / highlights.length : 100;
  const roundedFlexPercent = Math.round(flexPercent * 100) / 100;
  const highlightRef = useRef(null);

  useCssVariables(highlightRef, {
    '--flex-percent': `${roundedFlexPercent}%`
  });

  return (
    <section className={getSectionClassNames(componentBackgroundColor)}>
      <div className="container-fluid">
        <div className="highlights-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            {title && (
              <GlobalComponentContainer
                {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
              />
            )}
            <div
              className={classNames('highlights-container', { margin: !!shortDescription })}
              ref={highlightRef}
            >
              {highlights.map((highlight) => (
                <Highlight key={highlight.title} flexPercent={flexPercent} {...highlight} />
              ))}
            </div>
            {hasContentDivider && <ContentDivider />}
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

HighlightsComponent.defaultProps = {
  parentPageTitle: null,
  parentPageUrl: null,
  prettyUrlTitle: '',
  shortDescription: null,
  title: null,
  highlights: [],
  componentBackgroundColor: 'white'
};

HighlightsComponent.propTypes = {
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  hasContentDivider: PropTypes.bool.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.object),
  componentBackgroundColor: PropTypes.string
};

export default HighlightsComponent;
