import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { Player } from '@lottiefiles/react-lottie-player';

import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ContentDivider from '@/components/widgets/shared/ContentDivider';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import DesktopContainer from '@/components/shared/DesktopContainer';
import TabletAndMobileContainer from '@/components/shared/TabletAndMobileContainer';
import { getSectionClassNames } from '@/utilities/component-color';

import { sizedImageShape, animationShape } from '@/types';

const GeneralTextComponent = ({
  altText,
  description,
  hasContentDivider,
  image,
  imagePosition,
  imageUrl,
  imageUrlTarget,
  restrictHeight,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  shortDescription,
  title,
  animation,
  componentBackgroundColor
}) => {
  const hasAnimation = animation && animation.animationUrl;
  const hasImage = image && image.url;
  const hasMedia = hasAnimation || hasImage;

  return (
    <section className={getSectionClassNames(componentBackgroundColor)}>
      <div className="container-fluid">
        <div className="general-text-component section-gutter">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            {title && (
              <GlobalComponentContainer
                {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
              />
            )}
            <div className={`description-container ${imagePosition || 'left'}`}>
              {description && (
                <div className="content" dangerouslySetInnerHTML={{ __html: description }} />
              )}
              {hasMedia && (
                <div className="image">
                  {hasImage ? (
                    <ConditionalWrapper
                      condition={imageUrl}
                      wrapper={(children) => (
                        <a
                          href={imageUrl}
                          target={imageUrlTarget}
                          title={`Link for ${altText || title}`}
                        >
                          {children}
                        </a>
                      )}
                    >
                      <LazyLoadImg
                        src={image}
                        alt={altText || title}
                        className={restrictHeight ? 'restrict-height' : ''}
                      />
                    </ConditionalWrapper>
                  ) : (
                    <>
                      <DesktopContainer>
                        <Player
                          src={animation.animationUrl}
                          background={animation.backgroundColor}
                          speed={animation.animationSpeed}
                          style={{
                            width: animation.width,
                            height: animation.height
                          }}
                          loop={animation.loop}
                          autoplay={animation.autoplay}
                          hover={animation.hover}
                          mode={animation.playMode}
                        />
                      </DesktopContainer>
                      <TabletAndMobileContainer>
                        <Player
                          src={animation.animationUrl}
                          background={animation.backgroundColor}
                          speed={animation.animationSpeed}
                          style={{
                            width: animation.deviceWidth,
                            height: animation.deviceHeight
                          }}
                          loop={animation.loop}
                          autoplay={animation.autoplay}
                          hover={animation.hover}
                          mode={animation.playMode}
                        />
                      </TabletAndMobileContainer>
                    </>
                  )}
                </div>
              )}
            </div>
          </ConditionalWrapper>
        </div>
        {hasContentDivider && <ContentDivider />}
      </div>
    </section>
  );
};

GeneralTextComponent.defaultProps = {
  altText: null,
  description: '',
  hasContentDivider: false,
  image: {},
  imageUrl: '',
  imageUrlTarget: '',
  restrictHeight: false,
  parentPageTitle: null,
  parentPageUrl: null,
  prettyUrlTitle: '',
  shortDescription: null,
  title: null,
  animation: {},
  componentBackgroundColor: 'white'
};

GeneralTextComponent.propTypes = {
  altText: PropTypes.string,
  description: PropTypes.string,
  hasContentDivider: PropTypes.bool,
  image: sizedImageShape,
  imagePosition: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageUrlTarget: PropTypes.string,
  restrictHeight: PropTypes.bool,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string,
  animation: animationShape,
  componentBackgroundColor: PropTypes.string
};

export default GeneralTextComponent;
