import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import { searchHeaderShape } from '@/types';
import { getSectionClassNames } from '@/utilities/component-color';

import ContentDivider from '@/components/widgets/shared/ContentDivider';
import StateSelectorComponent from './StateSelectorComponent';

const ProductNetworkAvailabilityComponent = ({
  title,
  hasContentDivider,
  prettyUrlTitle,
  searchHeader,
  states,
  componentBackgroundColor
}) => (
  <section
    className={`${getSectionClassNames(componentBackgroundColor)} section-network-availability`}
  >
    <ConditionalWrapper
      condition={prettyUrlTitle}
      wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
    >
      <div className="container-fluid">
        {title && (
          <div className="global-props">
            <h2 className="section-title">{title}</h2>
          </div>
        )}
        {states && (
          <StateSelectorComponent
            {...{
              searchHeader,
              states,
              showContentDividerInitialState: false,
              showDataTableContentDivider: false
            }}
          />
        )}
        {hasContentDivider && <ContentDivider />}
      </div>
    </ConditionalWrapper>
  </section>
);

ProductNetworkAvailabilityComponent.defaultProps = {
  title: '',
  hasContentDivider: false,
  prettyUrlTitle: '',
  searchHeader: {},
  states: [],
  componentBackgroundColor: 'white'
};

ProductNetworkAvailabilityComponent.propTypes = {
  title: PropTypes.string,
  hasContentDivider: PropTypes.bool,
  prettyUrlTitle: PropTypes.string,
  searchHeader: searchHeaderShape,
  states: PropTypes.arrayOf(PropTypes.object),
  componentBackgroundColor: PropTypes.string
};

export default ProductNetworkAvailabilityComponent;
