import PropTypes from 'prop-types';

export const animationShape = PropTypes.shape({
  animationUrl: PropTypes.string,
  autoplay: PropTypes.bool,
  hover: PropTypes.bool,
  loop: PropTypes.bool,
  playMode: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  animationSpeed: PropTypes.number
});

export const authorShape = PropTypes.shape({
  name: PropTypes.string,
  bio: PropTypes.string,
  photo: PropTypes.string
});

export const sizedImageChild = PropTypes.shape({
  height: PropTypes.number,
  url: PropTypes.string,
  width: PropTypes.number
});

export const sizedImageShape = PropTypes.shape({
  sizedImage: sizedImageChild,
  height: PropTypes.number,
  url: PropTypes.string,
  width: PropTypes.number
});

export const mediaFile = PropTypes.shape({
  directUrl: PropTypes.string,
  fileTitle: PropTypes.string,
  permanentUrl: PropTypes.string
});

export const globalImage = PropTypes.shape({
  logo: mediaFile,
  logoInverse: mediaFile
});

export const businessUnit = PropTypes.shape({
  content: PropTypes.string,
  guid: PropTypes.string,
  landingPageUrl: PropTypes.string,
  resourceLinkTitle: PropTypes.string,
  resourceLinks: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
});

export const ctaButton = PropTypes.shape({
  text: PropTypes.string,
  path: PropTypes.string,
  backgroundColor: PropTypes.string,
  target: PropTypes.string,
  textColor: PropTypes.string,
  position: PropTypes.string,
  buttonColor: PropTypes.string,
  overrideButtonColor: PropTypes.bool
});

export const background = PropTypes.shape({
  type: PropTypes.string,
  value: PropTypes.string
});

export const largeHeroSlide = PropTypes.shape({
  altText: PropTypes.string,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  textColor: PropTypes.string,
  textPosition: PropTypes.string,
  ctaButton,
  desktopBackground: background,
  mobileBackground: background
});

export const smallHeroSlide = PropTypes.shape({
  title: PropTypes.string,
  eyebrowText: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  ctaButton,
  colorScheme: PropTypes.string,
  overrideColorScheme: PropTypes.bool
});

export const whiteLabelSmallHeroBanner = PropTypes.shape({
  title: PropTypes.string,
  eyebrowText: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textPosition: PropTypes.string,
  ctaButton
});

export const globalComponentProperties = {
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string.isRequired
};

export const globalComponentDefaults = {
  parentPageTitle: '',
  parentPageUrl: '',
  shortDescription: ''
};

export const video = PropTypes.shape({
  guid: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  imageHalf: PropTypes.string,
  playIconColor: PropTypes.string,
  videoUrl: PropTypes.string
});

export const navigationObject = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  title: PropTypes.string
});

export const newsroomInfoShape = PropTypes.shape({
  contentType: PropTypes.string,
  thumbnailImage: PropTypes.shape(sizedImageShape),
  title: PropTypes.string,
  link: PropTypes.string
});

export const filtersShape = PropTypes.shape({
  Filters: PropTypes.array,
  Listing: PropTypes.array,
  Query: PropTypes.string
});

export const valuesShape = PropTypes.shape({
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string
});

export const selectedItemShape = PropTypes.shape({
  title: PropTypes.string,
  alias: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object)
});

export const searchHeaderShape = PropTypes.shape({
  subTitle: PropTypes.string,
  Title: PropTypes.string
});

export const pagerShape = PropTypes.shape({
  currentPage: PropTypes.number,
  endPage: PropTypes.number,
  pageSize: PropTypes.number,
  startPage: PropTypes.number,
  totalItems: PropTypes.number,
  totalPages: PropTypes.number
});

export const productNavigationChildItem = PropTypes.shape({
  title: PropTypes.string,
  url: PropTypes.string
});

export const productNavigationItem = PropTypes.shape({
  nextProduct: PropTypes.shape(productNavigationChildItem),
  previousProduct: PropTypes.shape(productNavigationChildItem),
  title: PropTypes.string,
  url: PropTypes.string
});

export const ccpaBannerShape = PropTypes.shape({
  text: PropTypes.string
});

export const fullWidthImageCarouselSlideShape = PropTypes.shape({
  desktopImage: sizedImageShape,
  desktopImageAltText: PropTypes.string,
  mobileImage: sizedImageShape,
  mobileImageAltText: PropTypes.string
});

export const menuItemShape = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.array
});
