import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sizedImageShape } from '@/types';
import { useCssVariables } from '@/utilities';

const TestimonialMobile = ({ altText, author, colorScheme, icon, image, quote }) => {
  const testimonialImageRef = useRef(null);

  useCssVariables(testimonialImageRef, {
    '--background-image': `url("${image.url}")`
  });

  return (
    <div className={classNames('testimonial br-xs', colorScheme.toLowerCase())}>
      <div className="testimonial-body">
        {image.url && <div ref={testimonialImageRef} className="testimonial-image" />}
        <div className={classNames('testimonial-content', { margin: !!image.url })}>
          {icon && <img className="icon-start" src={icon} alt={altText || 'icon'} />}
          <h2 dangerouslySetInnerHTML={{ __html: quote }} />
          <h3 dangerouslySetInnerHTML={{ __html: author }} />
          {icon && <img className="icon-end" src={icon} alt={altText || 'icon'} />}
        </div>
      </div>
    </div>
  );
};

TestimonialMobile.defaultProps = {
  altText: '',
  icon: null,
  image: null
};

TestimonialMobile.propTypes = {
  altText: PropTypes.string,
  author: PropTypes.string.isRequired,
  colorScheme: PropTypes.string.isRequired,
  icon: PropTypes.string,
  image: sizedImageShape,
  quote: PropTypes.string.isRequired
};

export default TestimonialMobile;
