import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Element } from 'react-scroll';
import { Player } from '@lottiefiles/react-lottie-player';

import ContentDivider from '@/components/widgets/shared/ContentDivider';
import PlayIcon from '@/components/widgets/shared/PlayIcon';
import DesktopAndTabletContainerFull from '@/components/shared/DesktopAndTabletContainerFullWidth';
import MobileContainerFull from '@/components/shared/MobileContainerFullWidth';
import DesktopContainer from '@/components/shared/DesktopContainer';
import TabletAndMobileContainer from '@/components/shared/TabletAndMobileContainer';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import VideoModal from '@/components/widgets/shared/VideoModal';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import { getSectionClassNames } from '@/utilities/component-color';

import { animationShape } from '@/types';

const DetailedContentComponent = ({
  detailedContentItems,
  hasContentDivider,
  componentBackgroundColor
}) => {
  const [selectedContentGuid, selectContent] = useState(null);

  const setSelectedContent = (guid) => {
    if (guid === selectedContentGuid) {
      selectContent(null);
    } else {
      selectContent(guid);
    }
  };

  const selectedContent =
    detailedContentItems && detailedContentItems.find((item) => item.guid === selectedContentGuid);

  ReactModal.setAppElement('#wrapper');

  return (
    <section className={getSectionClassNames(componentBackgroundColor)}>
      <div className="container-fluid">
        {detailedContentItems.map((item) => {
          const hasAnimation = item.animation && item.animation.animationUrl;
          const hasImage = item.image && item.image.url;
          const hasMedia = hasAnimation || hasImage;

          return (
            <div className="section-gutter-half" key={item.guid}>
              <ConditionalWrapper
                condition={item.prettyUrlTitle}
                wrapper={(children) => <Element name={item.prettyUrlTitle}>{children}</Element>}
              >
                <div className={`section-intro ${item.textPosition}`} key={item.guid}>
                  <div className="content">
                    <h2>
                      <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    </h2>
                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>

                  <div
                    className={`content-right${hasMedia && hasImage && !item.videoUrl ? ' restrict-height' : ''}`}
                  >
                    <ConditionalWrapper
                      condition={item.videoUrl}
                      wrapper={(children) => (
                        <button
                          className={`btn-no-style position-relative${item.restrictHeight ? ' restrict-height' : ''}`}
                          type="button"
                          onClick={() => setSelectedContent(item.guid)}
                        >
                          {children}
                        </button>
                      )}
                    >
                      {item.videoUrl && <PlayIcon iconColor={item.playIconColor} />}
                      {item.image && item.image.url && (
                        <ConditionalWrapper
                          condition={item.imageUrl && !item.videoUrl}
                          wrapper={(children) => (
                            <a
                              href={item.imageUrl}
                              target={item.imageUrlTarget}
                              title={`Link for ${item.altText || item.title}`}
                            >
                              {children}
                            </a>
                          )}
                        >
                          {item.imageMobile && item.imageMobile.url ? (
                            <>
                              <MobileContainerFull>
                                <LazyLoadImg
                                  className={`${item.restrictHeight ? ' restrict-height' : ''}`}
                                  src={item.imageMobile}
                                  alt={item.altText ? item.altText : `Media for ${item.title}`}
                                />
                              </MobileContainerFull>
                              <DesktopAndTabletContainerFull>
                                <LazyLoadImg
                                  className={`${item.restrictHeight ? ' restrict-height' : ''}`}
                                  src={item.image}
                                  alt={item.altText ? item.altText : `Media for ${item.title}`}
                                />
                              </DesktopAndTabletContainerFull>
                            </>
                          ) : (
                            <LazyLoadImg
                              className={`${item.restrictHeight ? ' restrict-height' : ''}`}
                              src={item.image}
                              alt={item.altText ? item.altText : `Media for ${item.title}`}
                            />
                          )}
                        </ConditionalWrapper>
                      )}
                      {item.animation.animationUrl && (
                        <>
                          <DesktopContainer>
                            <Player
                              src={item.animation.animationUrl}
                              background={item.animation.backgroundColor}
                              speed={item.animation.animationSpeed}
                              style={{
                                width: item.animation.width,
                                height: item.animation.height
                              }}
                              loop={item.animation.loop}
                              autoplay={item.animation.autoplay}
                              hover={item.animation.hover}
                              mode={item.animation.playMode}
                            />
                          </DesktopContainer>
                          <TabletAndMobileContainer>
                            <Player
                              src={item.animation.animationUrl}
                              background={item.animation.backgroundColor}
                              speed={item.animation.animationSpeed}
                              style={{
                                width: item.animation.deviceWidth,
                                height: item.animation.deviceHeight
                              }}
                              loop={item.animation.loop}
                              autoplay={item.animation.autoplay}
                              hover={item.animation.hover}
                              mode={item.animation.playMode}
                            />
                          </TabletAndMobileContainer>
                        </>
                      )}
                    </ConditionalWrapper>
                  </div>
                </div>
              </ConditionalWrapper>
            </div>
          );
        })}
        <VideoModal
          {...selectedContent}
          selectFunction={setSelectedContent}
          isActive={!!selectedContentGuid}
        />
        {hasContentDivider && <ContentDivider />}
      </div>
    </section>
  );
};

DetailedContentComponent.defaultProps = {
  detailedContentItems: [],
  hasContentDivider: false,
  animation: {},
  componentBackgroundColor: 'white'
};

DetailedContentComponent.propTypes = {
  detailedContentItems: PropTypes.arrayOf(PropTypes.object),
  hasContentDivider: PropTypes.bool,
  animation: animationShape,
  componentBackgroundColor: PropTypes.string
};

export default DetailedContentComponent;
