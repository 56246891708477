import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import arrowUp from 'images/icons/layout/Arrow-Up-Purple.svg';
import arrowDown from 'images/icons/layout/Arrow-Down-Blue.svg';
import DesktopContainer from '@/components/shared/DesktopContainer';
import TabletAndMobileContainer from '@/components/shared/TabletAndMobileContainer';

const BusinessUnitDetail = ({
  content,
  landingPageUrl,
  title,
  resourceLinks,
  resourceLinkTitle
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasResourceLinks = !!(resourceLinks && resourceLinks.length);
  const toggleSubMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <DesktopContainer>
        <div className="business-unit-wrapper">
          <div className="business-unit-details">
            <div className="row">
              <div className="col-sm-6">
                <p data-nosnippet dangerouslySetInnerHTML={{ __html: content }} />
                <a href={landingPageUrl} title={title}>{`More about ${title}`}</a>
              </div>
              <div className="col-sm-6">
                {hasResourceLinks && <h3>{resourceLinkTitle}</h3>}
                {resourceLinks.map((link) => (
                  <a key={link.name} href={link.url} target={link.target} title={link.name}>
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </DesktopContainer>
      <TabletAndMobileContainer>
        <li>
          <button type="button" className="btn-no-style" onClick={toggleSubMenu}>
            <span className="btn-contain">
              <p className={isExpanded ? 'active' : ''}>{title}</p>
            </span>
            <img src={isExpanded ? arrowUp : arrowDown} className="chevron" alt="Chevron Arrow" />
          </button>

          <AnimateHeight duration={500} height={isExpanded ? 'auto' : 0} animateOpacity>
            <div className="business-unit-details">
              <a href={landingPageUrl}>{`About ${title}`}</a>
              {resourceLinks.map((link) => (
                <a key={link.name} href={link.url} target={link.target} title={link.name}>
                  {link.name}
                </a>
              ))}
            </div>
          </AnimateHeight>
        </li>
      </TabletAndMobileContainer>
    </>
  );
};

BusinessUnitDetail.defaultProps = {
  content: '',
  landingPageUrl: '',
  title: '',
  resourceLinks: [],
  resourceLinkTitle: ''
};

BusinessUnitDetail.propTypes = {
  content: PropTypes.string,
  landingPageUrl: PropTypes.string,
  title: PropTypes.string,
  resourceLinks: PropTypes.arrayOf(PropTypes.object),
  resourceLinkTitle: PropTypes.string
};

export default BusinessUnitDetail;
