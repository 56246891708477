import React from 'react';
import PropTypes from 'prop-types';

const PrivacyPolicy = ({ description, id, title }) => (
  <div className="item" id={id}>
    <h3>{title}</h3>
    <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
  </div>
);

PrivacyPolicy.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default PrivacyPolicy;
