import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import LazyLoadImg from '@/components/widgets/shared/LazyLoadImg';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import DesktopContainer from '@/components/shared/DesktopContainer';
import TabletAndMobileContainer from '@/components/shared/TabletAndMobileContainer';

import { sizedImageShape, animationShape } from '@/types';

const Item = ({ altText, description, image, link, target, animation }) => {
  const hasAnimation = animation && animation.animationUrl;
  const hasImage = image && image.url;
  const hasMedia = hasAnimation || hasImage;

  return (
    <div className="item">
      <div className="inner">
        {hasMedia && (
          <ConditionalWrapper
            condition={link}
            wrapper={(children) => (
              <a href={link} {...(target && { target })}>
                {children}
              </a>
            )}
          >
            {hasImage ? (
              <LazyLoadImg src={image} alt={altText || 'item icon'} />
            ) : (
              <>
                <DesktopContainer>
                  <Player
                    src={animation.animationUrl}
                    background={animation.backgroundColor}
                    speed={animation.animationSpeed}
                    style={{
                      width: animation.width,
                      height: animation.height
                    }}
                    loop={animation.loop}
                    autoplay={animation.autoplay}
                    hover={animation.hover}
                    mode={animation.playMode}
                  />
                </DesktopContainer>
                <TabletAndMobileContainer>
                  <Player
                    src={animation.animationUrl}
                    background={animation.backgroundColor}
                    speed={animation.animationSpeed}
                    style={{
                      width: animation.deviceWidth,
                      height: animation.deviceHeight
                    }}
                    loop={animation.loop}
                    autoplay={animation.autoplay}
                    hover={animation.hover}
                    mode={animation.playMode}
                  />
                </TabletAndMobileContainer>
              </>
            )}
          </ConditionalWrapper>
        )}
        {description && <div className="text" dangerouslySetInnerHTML={{ __html: description }} />}
      </div>
    </div>
  );
};

Item.defaultProps = {
  altText: null,
  description: null,
  image: {},
  link: null,
  target: null,
  animation: {}
};

Item.propTypes = {
  altText: PropTypes.string,
  description: PropTypes.string,
  image: sizedImageShape,
  link: PropTypes.string,
  target: PropTypes.string,
  animation: animationShape
};

export default Item;
