import React, { useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import DesktopContainer from '@/components/shared/DesktopContainer';
import TabletAndMobileContainer from '@/components/shared/TabletAndMobileContainer';
import GlobalComponentContainer from '@/components/widgets/GlobalComponentContainer';
import ConditionalWrapper from '@/components/shared/conditionalWrapper/ConditionalWrapper';
import Tile from '@/components/shared/tile/Tile';
import { getSectionClassNames } from '@/utilities/component-color';
import ProductsServicesTileExpansion from './ProductsServicesTileExpansion';
import ProductsServicesModalExpansion from './ProductsServicesModalExpansion';

const ProductsServicesComponent = ({
  parentPageName,
  parentPageTitle,
  parentPageUrl,
  prettyUrlTitle,
  products,
  shortDescription,
  title,
  leftArrow,
  rightArrow,
  closeIcon,
  componentBackgroundColor
}) => {
  let sliderRef;
  const [selectedProductGuid, selectProduct] = useState();
  const [selectedContentId, selectContent] = useState(0);
  const [isSlider, setIsSlider] = useState(true);

  const selectedProduct = products && products.find((tile) => tile.guid === selectedProductGuid);

  const settings = {
    arrows: false,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    lazyLoad: true,
    onReInit: () => {
      if (sliderRef) {
        setIsSlider(sliderRef.innerSlider.state.slideCount > 4);
      }
    }
  };

  const setSelectedProduct = (guid) => {
    if (guid === selectedProductGuid) {
      selectProduct();
    } else {
      selectProduct(guid);
    }
    // Sets overview content back to active when tile state changes
    selectContent(0);
  };

  const nextSlide = () => {
    sliderRef.slickNext();
    selectProduct();
  };

  const prevSlide = () => {
    sliderRef.slickPrev();
    selectProduct();
  };

  const productCount = products.length;
  return (
    <section className={getSectionClassNames(componentBackgroundColor)}>
      <div className="container-fluid">
        <div className="section-products-services">
          <ConditionalWrapper
            condition={prettyUrlTitle}
            wrapper={(children) => <Element name={prettyUrlTitle}>{children}</Element>}
          >
            <div className="content">
              <div className="content-left">
                <GlobalComponentContainer
                  {...{ parentPageTitle, parentPageUrl, shortDescription, title }}
                />
              </div>
              {productCount >= settings.slidesToShow + 1 && (
                <div className="content-right">
                  <p>{`${productCount} Products & Services`}</p>
                </div>
              )}
            </div>
            <DesktopContainer>
              <div className={isSlider ? 'tiles' : 'tiles no-slider'}>
                {isSlider && (
                  <span className="navigation" id="previous-arrow">
                    <button className="btn-no-style" type="button" onClick={() => prevSlide()}>
                      <img src={leftArrow} alt="Back Arrow" />
                    </button>
                  </span>
                )}
                {isSlider ? (
                  <Slider
                    ref={(component) => {
                      sliderRef = component;
                    }}
                    {...settings}
                  >
                    {products.map((product) => (
                      <Tile
                        altText={product.altText}
                        selectItem={setSelectedProduct}
                        image={product.image}
                        title={product.title}
                        statement={product.shortDescription}
                        guid={product.guid}
                        isActive={product.guid === selectedProductGuid}
                        key={product.guid}
                      />
                    ))}
                  </Slider>
                ) : (
                  products.map((product) => (
                    <Tile
                      altText={product.altText}
                      selectItem={setSelectedProduct}
                      image={product.image}
                      title={product.title}
                      statement={product.shortDescription}
                      guid={product.guid}
                      isActive={product.guid === selectedProductGuid}
                      key={product.guid}
                    />
                  ))
                )}
                {isSlider && (
                  <span className="navigation" id="next-arrow">
                    <button className="btn-no-style" type="button" onClick={() => nextSlide()}>
                      <img src={rightArrow} alt="Next Arrow" />
                    </button>
                  </span>
                )}
              </div>
              {selectedProductGuid && (
                <ProductsServicesTileExpansion
                  isSlider={isSlider}
                  selectedContentId={selectedContentId}
                  selectContent={selectContent}
                  selectProduct={selectProduct}
                  closeIcon={closeIcon}
                  {...selectedProduct}
                />
              )}
            </DesktopContainer>
            <TabletAndMobileContainer>
              <div className="tiles">
                {products.map((product) => (
                  <Tile
                    altText={product.altText}
                    selectItem={setSelectedProduct}
                    image={product.image}
                    title={product.title}
                    statement={product.shortDescription}
                    guid={product.guid}
                    isActive={product.guid === selectedProductGuid}
                    key={product.guid}
                  />
                ))}
                <ProductsServicesModalExpansion
                  parentPageName={parentPageName}
                  selectedContentId={selectedContentId}
                  selectContent={selectContent}
                  selectProduct={selectProduct}
                  isActive={!!selectedProductGuid}
                  closeIcon={closeIcon}
                  {...selectedProduct}
                />
              </div>
            </TabletAndMobileContainer>
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

ProductsServicesComponent.defaultProps = {
  parentPageTitle: '',
  parentPageUrl: '',
  prettyUrlTitle: '',
  leftArrow: '',
  rightArrow: '',
  closeIcon: '',
  products: [],
  componentBackgroundColor: 'white'
};

ProductsServicesComponent.propTypes = {
  parentPageName: PropTypes.string.isRequired,
  parentPageTitle: PropTypes.string,
  parentPageUrl: PropTypes.string,
  prettyUrlTitle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  shortDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  leftArrow: PropTypes.string,
  rightArrow: PropTypes.string,
  closeIcon: PropTypes.string,
  componentBackgroundColor: PropTypes.string
};

export default ProductsServicesComponent;
